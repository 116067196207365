import { Form } from '@remix-run/react'

function DeleteIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke="currentColor"
			className="h-4 w-4"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
			/>
		</svg>
	)
}

export default function CartItem({ item, idx, canDelete = true }) {
	return (
		<div className="flex rounded-none bg-white shadow-lg">
			<div className="flex aspect-square w-1/4">
				{item.images.slice(0, 1).map((image, idx) => (
					<img key={idx} src={image} alt={item.name} className="object-cover" />
				))}
			</div>
			<div className="flex w-full flex-col space-y-[2px] p-2">
				<p className="text-sm font-medium">
					{idx + 1}: {item.name}
				</p>
				<p className="text-[11px] font-light text-gray-500">
					Part #: {item.productId}
				</p>
				<p className="text-xs font-light">Supplier: {item.supplierName}</p>
				<p className="text-xs font-light">Store: {item.storeName}</p>
				<p className="text-sm font-semibold">
					Price: ${item.price}{' '}
					{/* <span className="font-light">Qt: {item.quantity}</span> */}
				</p>
				<div className="flex flex-row justify-between">
					<p className="text-xs font-light">Qt: {item.quantity}</p>
					{canDelete && (
						<Form method="POST" className="my-auto -mt-2">
							<button
								type="submit"
								className="btn btn-circle btn-ghost btn-xs my-auto"
							>
								<DeleteIcon />
							</button>
							<input type="hidden" name="_action" value="deleteFromCart" />
							<input type="hidden" name="partId" value={item.id} />
						</Form>
					)}
				</div>
			</div>
		</div>
	)
}
